.header{
    width: 100%;
    height: 150px;
    margin-bottom: 20px;
    padding-top: 10px;
}

.header-wrapper{
    padding: 10px 36px 20px 20px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}
.header-stats{
    position: relative;
    opacity: 0;
}
.header-stats-bg{
    opacity: 0;
    position: absolute;
    top: 20px;
    left: 0;
    width: 338px;
    height: 71px;
    background-image: url('../../images/era_title_bg.png');
    background-repeat: no-repeat;
    background-size: contain;
}
.header-stats__title{
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 30px;

}


.header-progress-bar-block{
    width: 287px;
    height: auto;
}

.contant-bar{
    display: flex;
    align-items: center;
}

.contant-bar .plus{
    width: 24px;
    height: 24px;
    margin-left: 10px;
}

.header-progress-bar-block .header-progress-bar{
    width: 287px;
    height: 12px;
    background: #1D0F26 0 0 no-repeat padding-box;
    border-radius: 6px;
    overflow: hidden;
}

.header-progress-bar-block .header-progress-bar span{
    display: block;
    background-color: #F99715;
    height: 100%;
}

.header-progress-bar-block .progress-bar-info p{
    text-align: center;
    color: #FFFFFF;
    font-size: 14px;
    margin: 0;
}

/* .header-items{
    margin-left: 160px;
} */

.header-items .header-items_list{
    display: flex;
    padding: 0;
}

.header-items .header-items_list li{
    display: flex;
    align-items: center;
    /*background: url('../../images/vector23.png') no-repeat center center;*/
    background-size: contain;
    margin-right: 60px;
    position: relative;
    z-index: 1;
}

.header-items .header-items_list li::before{
    content: '';
    background: url('../../images/vector23.png') no-repeat center center;
    position: absolute;
    top: 6%;
    left: 22%;
    width: 100%;
    height: 85%;
    z-index: -1;
}

.header-items .header-items_list li:last-child{
    margin: 0;
}

.header-items .header-items_list li span{
    color: #fff;
    font-size: 18px;
    padding-left: 10px;
}

.header-items .header-items_list li img{
    width: 34px;
    height: 27px;
}

.header-user{
    color: #fff;
    font-weight: bold;

}

.header-user .aptos_logo{
    width: 24px;
}


.header-user .money{
    display: flex;
    align-items: center;
    font-size: 14px;
}

.header-user .money .crypto{
    margin-right: 10px;
    text-align: right;
}

.header-user .money p{
    margin: 0;
}



.header-user .money .wax{
    margin-left: 10px;
    cursor: pointer;
}

.header-user .money .wax.login{
    margin: 5px;
    cursor: default;
    margin-left: 15px;
}

.header-user .money .logout{
    cursor: pointer;
}


.header-user .money .rtp{
    color: #3BD946;

    margin-right: 4px;
}



.header-user p:last-child{
    display: flex;
    align-items: center;
    gap: 5px;
}

@media screen and (max-width: 1815px){
    .header-stats__title{
        margin-bottom: 27px;
    }

    .header-progress-bar-block .header-progress-bar{
        width: 270px;
        height: 10px;
    }

    .header-progress-bar-block .progress-bar-info p{
        font-size: 13px;
        margin-top: 3px;
    }

    .contant-bar .plus{
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }

}

@media screen and (max-width: 1699px) {
    .header{
        margin: 0;
    }

    .header-wrapper{
        padding: 10px 36px 20px 20px;
    }

    .header-items .header-items_list li span{
        font-size: 16px;
    }
    .header-stats__title{
        font-weight: 700;
        color: #fff;
        font-size: 16px;
        margin-bottom: 19px;
    }

    .header-stats__title h2{
        margin: 0;
    }

    .contant-bar .plus{
        width: 16px;
        height: 16px;
        margin-left: 10px;
    }


    .header-items .header-items_list li img{
        width: 28px;
        height: 25px;
    }

    .header-stats-bg{
        position: absolute;
        top: -2px;
        left: 0;
        width: 276px;
        height: 56px;
    }

    .header-progress-bar-block .header-progress-bar{
        width: 210px;
        height: 10px;
    }

    .header-progress-bar-block .progress-bar-info p{
        font-size: 12px;
        margin-top: 3px;
        width: 210px;
    }

}


@media screen and (max-width: 1200px) {
    .header{
        margin: 0;
    }

    .header-items{
        display: none;
    }
}

@media screen and (max-width: 768px){
    .header-stats__title{
        font-size: 18px;
    }

    .header-stats__under{
        height: 23px;
    }


    .header-user .money p img{
        width: 30px;
        height: 30px;
    }

    .header-user .money p:first-child{
        display: none;
    }
}