@font-face {
  font-family: 'ExoSemiBold';
  src: url('fonts/Exo-SemiBold.ttf') format('truetype'),
  url('fonts/Exo-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

body {
  margin: 0;
  font-family: 'ExoSemiBold', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button{
  font-family: 'ExoSemiBold', serif;
}

code {
  font-family: 'ExoSemiBold', serif;
}

a{
  text-decoration: none;
  color: #FFFFFF;
  display: flex;
}

.main-main{
  background: #725c5385;
  width: 1209px;
  height: 676px;
  background: url('images/main_window.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 10px;
  position: relative;
}

.item-level{
  text-align: center;
  margin: -3px;
  color: #FFFFFF;
  font-size: 12px;
}

.main-bg{
  background-color: rgb(0, 0, 0);
  background-image: url('./images/ingame_background.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.spinner-wrapper div{
  height: 100vh;
  align-items: center;
  justify-content: center;
}
