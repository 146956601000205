.new-era-popup-content{
    height: 543px;
    max-width: 1208px;
}
.new-era-popup-content .newera {
    background-image: url('../images/long_popup.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #815F50;
}

.newera > .close{
    cursor: pointer;
    position: absolute;
    right: 2%;
    top: 4%;
}

.modal.newera > .header-modal{
    padding: 38px 0 25px 0;
}

.modal.newera .era-left__img{
    width: 325px;
    height: 230px;
    margin: 0 auto;
}

.era-wrapper{
    display: flex;
    align-items: center;
    padding: 0 95px 25px 80px;
    justify-content: space-between;
}

.era-left{
    flex: 0 0 40%;
    max-width: 40%;
}



.era-left__title{
    background-image: url('../images/orange_title.png');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
    padding: 15px 0;
    position: relative;
    margin-bottom: 23px;
}

.era-left__title h3{
    color: #fff;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}

.era-right{
    flex: 0 0 60%;
    max-width: 60%;
    background: #b190506b;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #a57f3b;
}

.era-right__update{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    overflow-x: auto;
}

.era-update__title{
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
}

.era-update__item{
    height: 202px;
    width: 145px;
    margin: 10px;
}



.modal.newera > .actions button{
    background-image: url("../images/green_btn.png");
    background-size: cover;
    background-color: transparent;
    border: 0;
    background-repeat: no-repeat;
    cursor: pointer;
    padding: 13px 30px;
    color: #fff;
    border: 0;
    font-size: 24px;
    display: block;
    margin: 0 auto;

}