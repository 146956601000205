.modal {
    font-size: 12px;
    background-image: url('../images/popup1_bg.png');
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    color: #815F50;
    /*padding: 20px 50px;*/
    /*transform: translateX(-10%);*/
}

.research-card-content{
    width: 786px;
    height: 502px;
}

.modal > .header-modal {
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    padding: 47px 0 47px 0;
    color: #fff;
}

.modal > .content {
    width: 100%;
    /*padding: 10px 5px;*/
    text-align: center;
}

.upgrade > .content img{
    padding: 5px;
}

.upgrade > .content p{
    padding-bottom: 10px;
}

.modal > .actions {
    width: 100%;
    margin: auto;
    text-align: center;

}

.modal > .actions button{
    background-image: url("../images/green_btn.png");
    background-size: cover;
    background-color: transparent;
    border: 0;
    background-repeat: no-repeat;
    cursor: pointer;
    padding: 7px 64px;
    color: #fff;
    font-size: 24px;
    display: block;
    margin: 0 auto;
}

.modal > .close{
    position: absolute;
    right: 5%;
    top: 4%;
    cursor: pointer;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.822);
}

.popup-content {
    max-width: 900px;
    height: 507px;
}

.info-card{
    display: flex;
    margin-bottom: 40px;
}

.info-card__img{
    background: #6b4c3e3f;
    border-radius: 8px;
    margin: 0 30px 0 10px;
}



.info-card__text h4{
    font-size: 24px;
}
.info-card__text p{
    padding: 0;
}

.info-card ul{
    flex-direction: column;
    text-align: left;
    margin-right: 60px;
}

.info-card ul li{
    font-size: 16px;
}

.info-card ul span{
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.info-card__progress li{
    font-weight: bold;
    color: #000;
}

.li-wrapper{
    display: flex;
    align-items: center
}

.info-card__progress p:first-child{
    font-size: 16px;
    font-weight: 400;
    color: #815F50;
    text-align: left;
}

.info-card__progress p {
    padding-bottom: 20px;
    color: #000;
    font-weight: bold;
}

.info-card__progress img{
    width: 30px;
}

.research-wrapper{
    display: flex;
    justify-content: center;
    height: 211px;
    padding: 0 68px 69px 68px;
}

.research-left-img-modal{
    width: 325px;
    height: 211px;
}

.research-left-img-modal img{
    width: 100%;
    height: 100%;

}

.research-right-modal{
    text-align: center;
    padding: 10px;
    width: 325px;
    background-image: url("../images/violet_bg.png");
}

.research-right-modal h3{
    color: #fff;
    font-size: 25px;
    font-weight: bold;

}

.research-right-modal p{
    font-size: 14px!important;
    color: #fff;
}

.research-right-modal__header{
    background-image: url('../images/orange_title.png');
    background-repeat: no-repeat;
    background-size: 75%;
    background-position: center;
    padding: 40px 0;
}