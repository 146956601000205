
.main-main-contant.swap{
    justify-content: center;
}

.main-workplace.swap .market-list{
    width: 100%;
    height: 385px;
    padding: 0 0 15px 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.main-workplace.market .main-main{
    margin: 0;
}

.main-workplace.swap .swap-container{
    display: flex;
    gap: 40px;
    align-items: center;
    height: 524px;
    padding: 80px 85px 55px 85px;
}

.block-craft{
    width: 405px;
    height: 550px;
    background: #B99068 0% 0% no-repeat padding-box;
    border: 2px solid #AE845B;
    border-radius: 10px;

}

.or{
    margin: 0 auto;
    width: 50%;
    background: #e67111;
    text-align: center;
    margin-bottom: 0;
    color: #FFFFFF;
    padding: 2px 0;
}

.or p {
    margin: 0;
}

.block-craft_container{
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    overflow: auto;
    height: 90%;
}

.craft-item{
    width: 156px;
    height: 231px;
    cursor: pointer;
}

.craft-item.active {
    transform: scale(0.95);
    border: 3px solid #ecd900;
    background: url('../../images/select_workplace.png');
}

.craft-item img{
    width: 100%;
    height: 100%;
}

.block-selected-craft{
    width: 286px;
    height: 424px;
}

.block-selected-craft img{
    width: 100%;
    height: 100%;
}

.block-craft-info{
    width: 320px;
}

.title-block-craft-info {
    background: transparent url('../../images/orange_title.png') 0% 0% no-repeat padding-box;
    width: 100%;
    height: 80px;
    background-position: center;
    background-size: contain;
    display: flex;
    align-items: center; 
    justify-content: center; 
    margin-bottom: 22px;
}

.title-block-craft-info h3 {
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 0px 7px #FF9600CC;
    font-size: 28px;
}

.main-info-about-craft{
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
    margin-bottom: 43px;
}

.main-info-about-craft div{
    display: flex;
    align-items: center;
    gap: 8px;

}

.main-info-about-craft .info{
    margin: 0;
    color: #512B47;
    font-size: 18px;
}

.main-info-about-craft span{
    color: #FFFFFF;
    font-size: 20px;
}

.cost-block-craft{
    border-top: 1px solid #000;
}

.cost-block-craft .cost-aptos-token{
   margin-bottom: 15px;
   margin-top: 10px;
}

.cost-block-craft .cost-aptos-token p{
   text-align: center;
   margin: 0;
   color: #512B47;
   font-size: 18px;
   margin-bottom: 5px;
}

.cost-block-craft .cost-aptos-token span{
    display: block;
    text-align: center;
    margin: 0;
    color: #FFFFFF;
    font-size: 22px;
    margin-bottom: 8px;
 }
 
 .cost-block-craft .cost-resources{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
 }

.cost-block-craft .cost-resources div{
    width: 145px;
   display: flex;
   align-items: center;
   gap: 8px;
}

.cost-block-craft .cost-resources div:nth-child(even) {
    justify-content: flex-end;
}

.cost-block-craft .cost-resources div:nth-child(odd) {
    justify-content: flex-start;
}

.cost-block-craft .cost-resources span{
    color: #FFFFFF;
    font-size: 20px;
}


@media screen and (max-width: 1815px) {
    .main-workplace.swap .swap-container {
        padding: 60px 85px 55px 85px;
    }

    .block-craft {
        width: 305px;
        height: 474px;
        background: #B99068 0% 0% no-repeat padding-box;
        border: 2px solid #AE845B;
        border-radius: 10px;
    }
    
    .craft-item {
        width: 111px;
        height: 164px;
    }

    .block-selected-craft {
        width: 227px;
        height: 328px;
    }

    .block-craft-info {
        width: 278px;
    }

    .title-block-craft-info {
        margin-bottom: 0px;
    }

    .main-info-about-craft {
        margin-bottom: 15px;
    }

    .title-block-craft-info h3 {
        font-size: 22px;
    }

    .cost-block-craft .cost-aptos-token span {
        font-size: 18px;
    }

    .cost-block-craft .cost-resources span {
        font-size: 18px;
    }

    .cost-block-craft .cost-resources div {
        width: 110px;
    }

    .cost-resources img{
        width: 19px;
    }
    
}

@media screen and (max-width: 1690px) {

    .main-workplace.swap .swap-container {
        padding: 20px 84px 20px 85px;
        height: 468px;
    }

 .block-craft {
        width: 285px;
        height: 389px;
        background: #B99068 0% 0% no-repeat padding-box;
        border: 2px solid #AE845B;
        border-radius: 10px;
    }
    
    .craft-item {
        width: 98px;
        height: 141px;
    }

    .title-block-craft-info h3 {
        font-size: 22px;
    }

    .block-selected-craft {
        width: 227px;
        height: 328px;
    }

    .block-craft-info {
        width: 280px;
    }

    .title-block-craft-info {
        margin-bottom: 0px;
    }

    .main-info-about-craft .info {
        margin: 0;
        color: #512B47;
        font-size: 16px;
    }

    .main-info-about-craft span {
        color: #FFFFFF;
        font-size: 16px;
    }

    .main-info-about-craft {
        margin-bottom: 15px;
    }

    .cost-block-craft .cost-resources {
        gap: 5px;
        margin-bottom: 20px;
    }

    .cost-resources img{
        width: 19px;
    }

    .cost-block-craft .cost-aptos-token span {
        font-size: 18px;
    }

    .cost-block-craft .cost-resources span {
        font-size: 16px;
    }

    .cost-block-craft .cost-resources div {
        width: 110px;
    }
    
}

@media screen and (max-width: 1599px) {

    .craft-item {
        width: 90px;
        height: 131px;
    }
    
}