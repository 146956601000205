h3{
    margin: 0;
}

.main-workplace.research{
    justify-content: center;
}

.main-workplace.research .main-main{
    margin-right: 0;
}



.research .container{
    height: 100%;
    display: flex;
    padding: 40px 30px;
    margin-bottom: 40px;
}

.research-left{
    margin-top: 46px;
    padding-top: 30px;
    flex: 0 0 50%;
    height: 70%;
    max-width: 50%;
    border-right: 5px solid #ae8963;
    border-radius: 5px;
}

.research-left .research-left_img{
  
    width: 325px;
    height: 211px;
    margin: 0 auto;
}

.research-left .research-left_img img{
    width: 100%;
    height: 100%;
}

.research-left .research-left__info ul{
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 50px 65px 40px 65px;
    margin: 0 auto;
}

.research-left .research-left__info ul li{
    font-size: 18px;
    font-weight: bold;
   margin-bottom: 10px;
    text-align: center;
}

.research-left .research-left__info ul li span{
    margin-left: 5px;
    font-size: 22px;
}

.research-left .research-left__btn{
    text-align: center;
    width: 282px;
    height: 64px;
    margin: 0 auto;
}

.research-left .research-left__btn button{
    width: 100%;
    background-image: url("../../images/green_btn.png");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    font-weight: bold;
    font-size: 24px;
    cursor: pointer;
    height: 100%;
    background-color: transparent;
    border: 0;
}

.research-left__btn.completed button{
    background-image: url("../../images/unactive_green_btn.png");
    cursor: default;
}

.research-right{
    height: 80%;
    flex: 0 0 50%;
    max-width: 50%;
}

.research-right .research-right__title{
    background-image: url('../../images/orange_title.png');
    background-repeat: no-repeat;
    background-position: center;
    width: 396px;
    margin: 48px auto 34px auto;
    height: 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.research-right .research-right__title img{
    position: absolute;
    top: -4%;
    right: -4%;
}

.research-right .research-right__title h3{
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.research-right .research-right__img{
    width: 325px;
    height: 211px;
    margin: 0 auto;
}

.research-right .research-right__img img{
    width: 100%;
    height: 100%;
}

/*.research-right .progress-bar-block {*/
/*    color: #fff;*/
/*    font-size: 20px;*/
/*    font-weight: bold;*/
/*    text-align: center;*/
/*    background-image: url("../../images/desighn_bg.png");*/
/*    width: 325px;*/
/*    height: 88px;*/
/*    margin: 0 auto;*/
/*}*/

.research-right .progress-bar-block p{
    margin: 0;
}


.research-right .progress-bar{
    width: 287px;
    height: 18px;
    background: #1D0F26 0 0 no-repeat padding-box;
    border-radius: 9px;
    opacity: 1;
    margin: 30px auto 0 auto;
    overflow: hidden;
}

.research-right .progress-bar span{
    display: block;
    background-color: #F99715;
    height: 100%;
}

.research-right .research-right__info{
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
}

.research-right .research-right__btn{
margin-top: 43px;
text-align: center;
}

.research-right .research-right__btn button{
    background-color: transparent;
    background-image: url("../../images/green_btn.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 13px 30px;
    color: #fff;
    border: 0;
    font-size: 24px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
}

.research-right .research-right__btn .disabled{
    background-color: transparent;
    background-image: url("../../images/unactive_green_btn.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 13px 30px;
    color: #fff;
    border: 0;
    font-size: 24px;
    display: block;
    margin: 0 auto;
    cursor: default;
}


@media screen and (max-width: 1815px) {
    .research-left .research-left_img{
      width: 305px;
      height: 195px;
  }

    .research-left{
        padding-top: 15px;
    }

    .research-left .research-left__info ul{
        padding: 40px 65px 30px 65px;
    }

    .research-right .research-right__btn .disabled{
        font-size: 22px;
    }

    .research-left .research-left__btn button{
        font-size: 22px;
        width: 90%;
        height: 90%;
    }

    .research-right .research-right__title{
        margin: 35px auto 20px auto;
        height: 70px;
    }

    .research-right .research-right__info{
        font-size: 18px;
    }

    .research-right .research-right__btn button{
        font-size: 22px;
    }
}

@media screen and (max-width: 1699px) {

    .research-left .research-left_img{
        width: 270px;
        height: 150px;
    }

    /*.research-left .research-left_img{*/
    /*    width: 365px;*/
    /*    height: 225px;*/
    /*}*/


    .research-left{
        padding-top: 15px;
    }

    .research-left .research-left__info ul li{
        font-size: 16px;
    }

    .research-left .research-left__info ul li span{
        font-size: 18px;
    }

    .research-left .research-left__info ul{
        padding: 25px 65px 20px 65px;
    }

    .research-left .research-left__btn button{
        font-size: 20px;
        width: 80%;
        height: 80%;
    }

    .research-right .research-right__img{
        width: 295px;
        height: 185px;
    }

    .research-right .research-right__info{
        /*width: 295px;*/
        height: 30px;
        font-size: 16px;
    }

    .research-right .research-right__title{
        margin: 25px auto 20px auto;
        height: 65px;
    }

    .research-right .research-right__btn{
        margin-top: 0;
    }
    .research-right .research-right__btn button{
        font-size: 20px;
    }

    .research-right .research-right__btn .disabled{
        font-size: 20px;
    }
}


@media screen and (max-width: 1599px){

}

@media screen and (max-width: 1450px) {

.research{
    height: 92%;
}
.research .main-main{
   height: 515px;
}

.main-workplace.research .footer{
    margin-top: 160px;
}
}

@media screen and (max-width: 1370px) {
.research .main-main{
    height: 615px;
}

}