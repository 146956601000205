/*.stake{*/
/*    background-image: url('../../images/ingame_background.png');*/
/*    background-position: center;*/
/*    position: fixed;*/
/*    width: 100%;*/
/*    height: 100vh;*/
/*    overflow: auto;*/
/*}*/


.main-main-contant.stake{
    justify-content: center;
}

/*.main-workplace.stake .main-main-contant{*/
/*    padding: 0 0 20px 0;*/
/*    overflow: auto;*/
/*}*/

.main-workplace.stake .stake-list{
    width: 100%;
    height: 385px;
    padding: 0 0 15px 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.main-workplace.stake{
    justify-content: center;
}

.main-workplace.stake .main-main{
    margin: 0;
}

.main-workplace.stake .container{
    height: 580px;
    padding: 85px 85px 0 111px;
    margin-bottom: 40px;
}

.header-stake{
    margin-bottom: 5px;
}

.header-stake__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-stake__wrapper .filter select{
    margin: 10px;
    border: 2px solid #512B47;
    background: transparent;
    padding: 10px 30px;
    text-align: left;
    color: #512B47;
}

.header-stake__wrapper button{
    color: #E8D9BF;
    background: #3b1d36;
    padding: 10px 30px;
    border: 0;
    cursor: pointer;
}

.no-inventory{
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
}

.stake-list{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 991px;
    height: 304px;
    /* UI Properties */
    background: #B99068 0 0 no-repeat padding-box;
    border: 2px solid #AE845B;
    border-radius: 10px;
    opacity: 1;
    position: relative;
}

.alert{
    position: absolute;
    bottom: 100px;
    right: 100px;
    color: #FFFFFF;
    font-size: 12px;
}

.stake-list_item{
    height: 161px;
    margin: 10px 10px;
    cursor: pointer;
    position: relative;
}


.stake-list_item:hover{
    background-image: url('../../images/select_workplace.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 2;
}

.stake-list_item.selected{
    background-image: url('../../images/select_workplace.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 2;
}

.list-item-wrapper{
    width: 108px;
    height: 153px;
}

.stake-list_item img{
    width: 100%;
    height: 100%;
    transform: scale(0.94);
    padding-top: 5px;
}


.stake-btn{
    margin-top: 17px;
}

.main-main .add{
    position: absolute;
    top: 40px;
    left: 220px;
    color: #FFFFFF;
    font-size: 18px;
    border-bottom: 3px solid #512B47;
    cursor: pointer;
}

.main-main .add p{
    margin: 0;
    padding: 7px 44px;
}


.main-main .remove{
    position: absolute;
    top: 40px;
    left: 820px;
    color: #FFFFFF;
    font-size: 18px;
    border-bottom: 3px solid #512B47;
    cursor: pointer;
}

.main-main .remove p{
    margin: 0;
    padding: 7px 44px;
}

.main-main .add.active{
    background: #512B47 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 0px #0000004D;
    border-radius: 20px;
}

.main-main .remove.active{
    background: #512B47 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 0px #0000004D;
    border-radius: 20px;
}


.stake-btn button{
    background-color: transparent;
    background-image: url("../../images/green_btn.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px 40px;
    color: #fff;
    border: 0;
    font-size: 22px;
    display: block;
    margin: 0 auto;
    cursor: pointer;
}

.stake-lvl{
    color: #fff;
    font-weight: bold;
}

.stake-lvl p {
    font-size: 20px;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 1815px){
    .main-main .add{
        position: absolute;
        top: 40px;
        left: 155px;
        color: #FFFFFF;
        font-size: 16px;
        border-bottom: 3px solid #512B47;
        cursor: pointer;
    }

    .main-main .remove{
        position: absolute;
        top: 40px;
        left: 755px;
        color: #FFFFFF;
        font-size: 16px;
        border-bottom: 3px solid #512B47;
        cursor: pointer;
    }

    .main-workplace.stake .stake-list{
        height: 360px;
    }

    .alert{
        bottom: 80px;
        right: 100px;
    }

    .stake-btn{
        margin-top: 14px;
    }
}


@media screen and (max-width: 1690px) {
    .stake .main-main{
        height: 515px;
    }

    .main-workplace.stake .container{
        height: 430px;
    }

    .header-stake__wrapper .filter select{
        padding: 5px 15px;
    }

    .header-stake__wrapper button{
        padding: 5px 15px;
    }

    .main-workplace.stake .container{
        padding: 45px 85px 0 111px;
    }

    /*.stake-list_item.selected, .stake-list_item:hover{*/
    /*    width: 105px;*/
    /*    height: 161px;*/
    /*}*/

    .stake-list{
        width: 100%;
        height: 230px;
    }

    .main-workplace.stake .stake-list{
        height: 305px;
    }

    .list-item-wrapper{
        width: 105px;
        height: 150px;
    }

    .inventory .container{
        padding: 90px 55px;
        margin-bottom: 10px;
    }

    .inventory-item{
        width: 200px;
        height: 355px;
    }

    .inventory-item__btn button{
        font-size: 18px;
        padding: 5px 35px;
    }


    .stake-btn button{
        padding: 5px 40px;
    }

    .main-workplace.stake .stake-list{
        height: 285px;
    }

    .main-workplace.stake .container{
        padding: 70px 85px 0 111px;
        margin-bottom: 10px;
    }

    .main-main .add{
        font-size: 14px;
    }

    .main-main .add p{
        padding: 2px 35px;
    }

    .main-main .remove{
        font-size: 14px;
        left: 690px;
    }

    .main-main .remove p{
        padding: 2px 35px;
    }

    .alert{
        bottom: 65px;
        right: 100px;
    }

}

@media screen and (max-width: 1599px){
    .main-main .remove{
        left: 660px;
    }
}